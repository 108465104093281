import React from "react"
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql, withPrefix } from "gatsby";
import { useLocation } from "@reach/router";

function DefaultHelmet({description, lang, meta, title, keywords, microdata, imageUrl = null, appendPostfixToTitle = true}) {
    const titlePostfix = ' | UTD';

    const {site} = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        keywords
                        imageUrl
                    }
                }
            }
        `
    );

    const metaDescription = cropString(description || site?.siteMetadata?.description, 160);
    const pageTitle = cropString(
        title || site?.siteMetadata?.title, 70 - (appendPostfixToTitle ? titlePostfix.length : 0)
    );
    const metaKeywords = keywords || site?.siteMetadata?.keywords;
    const location = useLocation();

    const metaTags = [
        {
            name: `description`,
            content: metaDescription,
        },
        {
            property: `og:description`,
            content: metaDescription,
        },
        {
            property: `og:title`,
            content: pageTitle,
        },
        {
            property: `og:url`,
            content: location.href,
        },
        {
            property: `og:type`,
            content: `website`,
        },
        {
            name: "keywords",
            content: metaKeywords
        },
        {
            name: "viewport",
            content: "width=device-width, initial-scale=1, maximum-scale=1"
        }
    ];

    if (site?.siteMetadata?.imageUrl || imageUrl) {
        metaTags.push(
            {
                property: `og:image`,
                content: site?.siteMetadata?.imageUrl || imageUrl,
            }
        );
    }

    return (
      <Helmet
        htmlAttributes={{lang}}
        title={appendPostfixToTitle ? pageTitle + titlePostfix : pageTitle}
        meta={metaTags.concat(meta)}
      >
          {process.env.NODE_ENV !== 'development' && (
            <link
              href={withPrefix('main.min.css')}
              rel="stylesheet"
              type="text/css"
            />
          )}

          {
              microdata ?
                <script type="application/ld+json">{JSON.stringify(microdata)}</script>
                :
                null
          }

          <link rel="canonical" href={location.href}/>

          {/* GDPR */}
          {/** Privacy Controls and Cookie Solution */}
          <script type="text/javascript" src="//embeds.iubenda.com/widgets/a2f1d01e-8b3f-489d-97a6-52e5d4552384.js"/>

          {/** Consent Database */}
          <script type="text/javascript">
              {`var _iub = _iub || {}; _iub.cons_instructions = _iub.cons_instructions || []; _iub.cons_instructions.push(["init", {api_key: "C71qqe5NmU2T7AZUEHCyKGP3xwPw5ifM"}]);`}
          </script>
          <script type="text/javascript" src="https://cdn.iubenda.com/cons/iubenda_cons.js" async />
          {/* END GDPR */}
      </Helmet>
    );
}

DefaultHelmet.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
};

DefaultHelmet.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
};

const cropString = (str, length) => {
    if (str.length <= length) {
        return str;
    }
    return str.substring(0, length - 5) + '...';
};

export default DefaultHelmet;
